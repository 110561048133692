import i18n from '@/i18n';
import _ from 'lodash';
import Phone from '@/components/phone.vue';

const t = i18n.global.t;
const locale = i18n.global.locale.value;

export const MIGRATE_FROM = {
  amazon: t('constant.amazon'),
  unknown: t('constant.migrate_from.unknown')
};

export const MIGRATE_TO = {
  unknown: t('constant.migrate_to.unknown'),
  shopify: t('constant.shopify'),
  shoplazza: t('constant.shoplazza')
};

export const MIGRATE_TO_SITE_FORMAT_PLACEHOLDER = {
  unknown: '',
  shopify: 'https://mystore.myshopify.com',
  shoplazza: 'https://mystore.myshoplaza.com'
};

export const COUNTRY_CODE_MARKETPLACE_ID_MAP = {
  CA: 'Canada',
  US: 'United States of America',
  MX: 'Mexico',
  BR: 'Brazil',
  ES: 'Spain',
  UK: 'United Kingdom',
  FR: 'France',
  BE: 'Belgium',
  NL: 'Netherlands',
  DE: 'Germany',
  IT: 'Italy',
  SE: 'Sweden',
  PL: 'Poland',
  EG: 'Egypt',
  TR: 'Turkey',
  SA: 'Saudi Arabia',
  AE: 'United Arab Emirates',
  IN: 'India',
  SG: 'Singapore',
  AU: 'Australia',
  JP: 'Japan',
};

export const POLICY_TYPE = {
  refund_policy: t('constant.policy_type.refund_policy'),
  privacy_policy: t('constant.policy_type.privacy_policy'),
  terms_of_service: t('constant.policy_type.terms_of_service'),
  shipping_policy: t('constant.policy_type.shipping_policy'),
};

export const MIGRATE_STATUS = {
  pending: t('constant.migration_status.pending'),
  already_setup_store: t('constant.migration_status.already_setup_store'),
  added_policy: t('constant.migration_status.added_policy'),
  added_payment_method: t('constant.migration_status.added_payment_method'),
  added_theme: t('constant.migration_status.added_theme'),
  syncing: t('constant.migration_status.syncing'),
  completed: t('constant.migration_status.completed'),
};

export const OVERALL_ADJUSTMENT_PRICE_TYPE = {
  increase_percentage: t('constant.overall_adjustment_price_type.increase_percentage'),
  decrease_percentage: t('constant.overall_adjustment_price_type.decrease_percentage'),
};

export const MIGRATE_TASK_STATUS = {
  pending: t('constant.migrate_task_status.pending'),
  processing: t('constant.migrate_task_status.processing'),
  completed: t('constant.migrate_task_status.completed'),
};

export const GOOGLE_INTEGRATE_STATUS = {
  pending: t('constant.google_integrate_status.pending'),
  added_google_channel: t('constant.google_integrate_status.added_google_channel'),
  added_tracking_code: t('constant.google_integrate_status.added_tracking_code'),
  completed: t('constant.google_integrate_status.completed')
};

export enum INSTRUCTION_TYPE {
  // Shopify开通账号
  create_shopify_account = 'create_shopify_account',
  // Shoplazza开通账号
  create_shoplazza_account = 'create_shoplazza_account',
  // Shopify设置支付方式
  add_shopify_payment_method = 'add_shopify_payment_method',
  // Shoplazza设置支付方式
  add_shoplazza_payment_method = 'add_shoplazza_payment_method',
  // Google授权
  google_auth = 'google_auth',
  // 获取GA4 measurement ID
  get_google_analytics = 'get_google_analytics',
  // 安装Google channel app
  install_google_channel = 'install_google_channel',
  // 设置Google conversion code
  add_google_conversion_code = 'add_google_conversion_code',
  // 设置Shoplazza policy
  add_shoplazza_policy = 'add_shoplazza_policy',
  // Shoplazza集成Google
  shoplazza_google_integrate = 'shoplazza_google_integrate',
  // app block
  product_options_picker_app_embed_block = 'product_options_picker_app_embed_block',
  // 帮助中心
  help_center = 'help_center',
  // 引导客户官方提交申诉
  appeal_letter_flow_guide = 'appeal_letter_flow_guide',
  // ftz 帮助中心
  ftz_help_center = 'ftz_help_center',
  // Pmax 广告创建指南
  pmax_campaign_guide = 'pmax_campaign_guide',
  // 博客指南
  blog_guide = 'blog_guide',
  // 购物广告创建指南
  shopping_campaign_guide = 'shopping_campaign_guide',
  // 搜索广告创建指南
  search_campaign_guide = 'search_campaign_guide',
  // 亚马逊店铺选品指南
  amazon_product_choice_guide = 'amazon_product_choice_guide',
};

export const POLICY_VAR_CLASS_NAME = 'store-migration-tool-tpl-var';

export const POLICY_VAR_MAP = {
  company_name: {
    label: t('merchant.company_name'),
    form: {
      component: 'el-input',
      props: {}
    }
  },
  email: {
    label: t('merchant.email'),
    form: {
      component: 'el-input',
      props: {}
    }
  },
  company_address: {
    label: t('merchant.company_address') + t('form.use_en'),
    form: {
      component: 'el-input',
      props: {}
    }
  },
  company_established: {
    label: t('merchant.company_established'),
    form: {
      component: 'el-date-picker',
      props: {
        type: 'date',
        'value-format': 'YYYY-MM-DD'
      }
    }
  },
  return_address: {
    label: t('merchant.return_address') + t('form.use_en'),
    form: {
      component: 'el-input',
      props: {}
    }
  },
  return_contact: {
    label: t('merchant.return_contact') + t('form.use_en'),
    form: {
      component: 'el-input',
      props: {}
    }
  },
  return_phone: {
    label: t('merchant.return_phone_number'),
    form: {
      component: Phone,
      props: {}
    }
  },
  return_validity_time: {
    label: t('merchant.return_validity_time'),
    form: {
      component: 'el-input-number',
      props: {
        min: 1,
        max: 1000,
        step: 1,
        'step-strictly': true
      }
    }
  },
  return_email: {
    label: t('merchant.return_email'),
    form: {
      component: 'el-input',
      props: {}
    }
  },
  platform_host: {
    label: t('merchant.shop_subdomain'),
    form: {
      component: null,
      props: {}
    }
  }
};

export const CHECKOUT_PAGE_TRACKING_TARGET_URL = {
  shopify: '/admin/settings/checkout',
  shoplazza: '/admin/smart_apps/puma/checkout'
};

export const INSTALL_THEME_TARGET_URL = {
  shopify: '/admin/themes',
  shoplazza: '/admin/smart_apps/store/theme'
};

export const COMPAIGN_LANGUAGE = [
  { name: 'English', id: 1000 },
  { name: 'Spanish', id: 1003 },
  { name: 'French', id: 1002 },
  { name: 'Italian', id: 1004 },
  { name: 'Portuguese', id: 1014 },
  { name: 'Japanese', id: 1005 },
  { name: 'Korean', id: 1012 },
  { name: 'Arabic', id: 1019 },
  { name: 'Catalan', id: 1038 },
  { name: 'Filipino', id: 1042 },
  { name: 'Dutch', id: 1010 },
  { name: 'German', id: 1001 },
  { name: 'Thai', id: 1044 },
  { name: 'Vietnamese', id: 1040 },
  { name: 'Malay', id: 1102 },
  { name: 'Simplified Chinese', id: 1017 },
  { name: 'Traditional Chinese', id: 1018 },
];

export const COMPAIGN_LOCATION = [
  { name: 'United States', id: 2840 },
  { name: 'Japan', id: 2392 },
  { name: 'Hong Kong', id: 2344 },
  { name: 'Taiwan', id: 2158 },
  { name: 'Canada', id: 2124 },
  { name: 'United Kingdom', id: 2826 },
  { name: 'Australia', id: 2036 },
  { name: 'South Korea', id: 2410 },
  { name: 'Vietnam', id: 2704 },
  { name: 'Thailand', id: 2764 },
  { name: 'Singapore', id: 2702 },
  { name: 'Philippines', id: 2608 },
  { name: 'Malaysia', id: 2458 },
  { name: 'Spain', id: 2724 },
  { name: 'Brazil', id: 2076 },
  { name: 'France', id: 2250 },
  { name: 'Germany', id: 2276 },
  { name: 'Greece', id: 2300 },
  { name: 'Finland', id: 2246 },
  { name: 'Denmark', id: 2208 },
  { name: 'Mexico', id: 2484 },
  { name: 'Italy', id: 2380 }
];

export const COMPAIGN_LOCATION_KEY_BY_ID = _.mapValues(
  _.keyBy(COMPAIGN_LOCATION, 'id'),
  val => val.name
);

export const COMPAIGN_BID_STRATEGY_TYPE = [
  'CPC (enhanced)',
  'Manual CPC',
  'Maximize clicks',
  'Maximize Conversions',
  'Maximize Conversion Value',
  'Target Impression Share',
];

export const AUTOMATION_COMPAIGN_STATE = {
  pending: t('constant.automation_comaign_state.pending'),
  processing: t('constant.automation_comaign_state.processing'),
  completed: t('constant.automation_comaign_state.completed'),
};

export const AUTOMATION_COMPAIGN_BUSSINESS_TYPE = {
  B2B: 'B2B',
  B2C: 'B2C',
};

export const THEMES = {
  home_and_garden: {
    name: 'Home And Garden',
    product_image_resize: {
      width: 2048,
      height: 2048
    }
  },
  sports_and_recreation: {
    name: 'Sports And Recreation',
    product_image_resize: {
      width: 2048,
      height: 1465
    }
  },
  electronics: {
    name: 'Electronics',
    product_image_resize: {
      width: 2048,
      height: 2716
    }
  },
  clothing_and_accessories: {
    name: 'Clothing And Accessories',
    product_image_resize: {
      width: 2048,
      height: 3072
    }
  },
  business_equipment_and_supplies: {
    name: 'Business Equipment And Supplies',
    product_image_resize: {
      width: 2048,
      height: 2048
    }
  }
};

export const PROVINCES = [
  { 'label': '北京', 'value': 'Beijing' },
  { 'label': '天津', 'value': 'Tianjin' },
  { 'label': '河北', 'value': 'Hebei' },
  { 'label': '山西', 'value': 'Shanxi' },
  { 'label': '内蒙古', 'value': 'Inner Mongolia' },
  { 'label': '辽宁', 'value': 'Liaoning' },
  { 'label': '吉林', 'value': 'Jilin' },
  { 'label': '黑龙江', 'value': 'Heilongjiang' },
  { 'label': '上海', 'value': 'Shanghai' },
  { 'label': '江苏', 'value': 'Jiangsu' },
  { 'label': '浙江', 'value': 'Zhejiang' },
  { 'label': '安徽', 'value': 'Anhui' },
  { 'label': '福建', 'value': 'Fujian' },
  { 'label': '江西', 'value': 'Jiangxi' },
  { 'label': '山东', 'value': 'Shandong' },
  { 'label': '河南', 'value': 'Henan' },
  { 'label': '湖北', 'value': 'Hubei' },
  { 'label': '湖南', 'value': 'Hunan' },
  { 'label': '广东', 'value': 'Guangdong' },
  { 'label': '广西', 'value': 'Guangxi' },
  { 'label': '海南', 'value': 'Hainan' },
  { 'label': '重庆', 'value': 'Chongqing' },
  { 'label': '四川', 'value': 'Sichuan' },
  { 'label': '贵州', 'value': 'Guizhou' },
  { 'label': '云南', 'value': 'Yunnan' },
  { 'label': '西藏', 'value': 'Tibet' },
  { 'label': '陕西', 'value': 'Shaanxi' },
  { 'label': '甘肃', 'value': 'Gansu' },
  { 'label': '青海', 'value': 'Qinghai' },
  { 'label': '宁夏', 'value': 'Ningxia' },
  { 'label': '新疆', 'value': 'Xinjiang' }
].map(item => ({
  ...item,
  label: locale === 'zh_cn' ? item.label : item.value
}));


export const APPEAL_TARGET_AGE = [
  '18-34',
  '34-64',
  '65+',
];


export const APPEAL_TARGET_GENDER = {
  male: t('constant.appeal_target_gender.male'),
  female: t('constant.appeal_target_gender.female'),
  unknown: t('constant.appeal_target_gender.unknown'),
};

export const APPEAL_TARGET_INCOME = {
  low: t('constant.appeal_target_income.low'),
  mid: t('constant.appeal_target_income.mid'),
  high: t('constant.appeal_target_income.high'),
};

export const APPEAL_BUSSINESS_TYPE = {
  b2b: 'B2B',
  b2c: 'B2C',
};

export const APPEAL_RESASON = {
  gmc_block: t('constant.appeal_reason.gmc_block'),
  not_comply_terms_and_conditions: t('constant.appeal_reason.not_comply_terms_and_conditions'),
  other: t('constant.appeal_reason.other'),
};

export const APPEAL_CHANNEL_TYPE = {
  amazon: 'Amazon',
  walmart: 'Walmart',
  ebay: 'Ebay',
};

export const APPEAL_ACCOUNT_TYPE = {
  facebook: 'Facebook',
  instagram: 'Instagram',
  pinterest: 'Pinterest',
};

export const APPEAL_GENERATE_CONTENT_STATE = {
  pending: t('constant.appeal_generate_content_state.pending'),
  processing: t('constant.appeal_generate_content_state.processing'),
  completed: t('constant.appeal_generate_content_state.completed'),
};

export const APPEAL_INDUSTRIES = _.mapValues({
  'Animals & Pet Supplies': '宠物和宠物用品',
  'Apparel & Accessories': '服装和配饰',
  'Arts & Entertainment': '艺术和娱乐',
  'Baby & Toddler': '婴儿和幼儿',
  'Business & Industrial': '商业和工业',
  'Cameras & Optics': '相机和光学产品',
  'Electronics': '电子产品',
  'Food, Beverages & Tobacco': '食品、饮料和烟草',
  'Furniture': '家具',
  'Hardware': '硬件',
  'Health & Beauty': '健康和美容',
  'Home & Garden': '家居和花园',
  'Luggage & Bags': '行李和包',
  'Mature': '成人',
  'Media': '媒体',
  'Office Supplies': '办公用品',
  'Religious & Ceremonial': '宗教和仪式用品',
  'Software': '软件',
  'Sporting Goods': '体育用品',
  'Toys & Games': '玩具和游戏',
  'Vehicles & Parts': '车辆和零部件'
}, (value, key) => {
  return locale === 'zh_cn' ? value : key;
});

export const CAMPAIGN_TYPES: { value: 'Search' | 'Shopping' | 'Performance Max'; title: string; subtitle: string; }[] = [
  {
    value: 'Search',
    title: t('constant.campaign_types.Search.title'),
    subtitle: t('constant.campaign_types.Search.subtitle')
  },
  {
    value: 'Shopping',
    title: t('constant.campaign_types.Shopping.title'),
    subtitle: t('constant.campaign_types.Shopping.subtitle')
  },
  {
    value: 'Performance Max',
    title: t('constant.campaign_types.PMax.title'),
    subtitle: t('constant.campaign_types.PMax.subtitle')
  },
];

export const CAMPAIGN_TYPES_KEY_BY_VALUE = _.keyBy(CAMPAIGN_TYPES, 'value');

export const SHOPPING_CAMPAIGN_BID_STRATEGY_TYPES = {
  'Maximize clicks': {
    label: t('constant.shopping_campaign_bid_strategy_types.maximize_clicks.label'),
    descs: [
      t('constant.shopping_campaign_bid_strategy_types.maximize_clicks.descs.0'),
      t('constant.shopping_campaign_bid_strategy_types.maximize_clicks.descs.1'),
      t('constant.shopping_campaign_bid_strategy_types.maximize_clicks.descs.2'),
    ],
    link: 'https://support.google.com/google-ads/answer/6268626'
  },
  'Target ROAS': {
    label: t('constant.shopping_campaign_bid_strategy_types.target_ROAS.label'),
    descs: [
      t('constant.shopping_campaign_bid_strategy_types.target_ROAS.descs.0'),
      t('constant.shopping_campaign_bid_strategy_types.target_ROAS.descs.1'),
    ],
    link: 'https://support.google.com/google-ads/answer/6268637'
  },
  'Manual CPC': {
    label: t('constant.shopping_campaign_bid_strategy_types.manual_CPC.label'),
    descs: [
      t('constant.shopping_campaign_bid_strategy_types.manual_CPC.descs.0')
    ],
    link: 'https://support.google.com/google-ads/answer/2472725'
  }
};

export const PERFORMANCE_MAX_CAMPAIGN_BID_STRATEGY_TYPES = {
  'Maximize Conversions': {
    label: t('constant.pmaxCampaignBidStrategyTypes.maximizeConversions')
  },
  'Maximize Conversion Value': {
    label: t('constant.pmaxCampaignBidStrategyTypes.maximizeConversionValue')
  }
};

export const REVIEW_STATE = {
  hide: t('constant.review_state.hide'),
  published: t('constant.review_state.published'),
};

export const BOOLEAN_SELECT_MAP = [
  {
    value: true,
    label: t('constant.boolean_select_map.true')
  },
  {
    value: false,
    label: t('constant.boolean_select_map.false')
  }
];

export const GOOGLE_USER_LISTS_STATUS = {
  pending: {
    type: 'info',
    label: t('constant.googleUserListsStatus.pending')
  },
  running: {
    type: 'primary',
    label: t('constant.googleUserListsStatus.running')
  },
  success: {
    type: 'success',
    label: t('constant.googleUserListsStatus.success')
  },
  failed: {
    type: 'danger',
    label: t('constant.googleUserListsStatus.failed')
  },
};

export const BLOG_STATE = {
  pending: {
    type: 'info',
    label: t('constant.blogState.pending')
  },
  processing: {
    type: 'primary',
    label: t('constant.blogState.processing')
  },
  generated: {
    type: 'success',
    label: t('constant.blogState.generated')
  },
  pushing: {
    type: 'warning',
    label: t('constant.blogState.pushing')
  },
  pushed: {
    type: 'success',
    label: t('constant.blogState.pushed')
  },
};


export const TAMAR_AI_ACCOUNT_REPORT_STATUS = {
  pending: {
    type: 'info',
    label: t('constant.tamarAiAccountReportStatus.pending'),
  },
  in_progress: {
    type: 'primary',
    label: t('constant.tamarAiAccountReportStatus.in_progress'),
  },
  completed: {
    type: 'success',
    label: t('constant.tamarAiAccountReportStatus.completed'),
  },
  failed: {
    type: 'danger',
    label: t('constant.tamarAiAccountReportStatus.failed'),
  },
};

export const TAMAR_AI_ACCOUNT_STORE_TYPE = {
  seller: t('constant.tamarAiAccountStoreType.seller'),
  vendor: t('constant.tamarAiAccountStoreType.vendor'),
};

export const TAMAR_AI_ACCOUNT_REGION = {
  NA: t('constant.tamarAiAccountRegion.NA'),
  EU: t('constant.tamarAiAccountRegion.EU'),
  FE: t('constant.tamarAiAccountRegion.FE'),
};

export const POI_INSIGHTS_REPORT_STATUS = {
  pending: t('constant.poiInsightsReportStatus.pending'),
  in_progress: t('constant.poiInsightsReportStatus.in_progress'),
  completed: t('constant.poiInsightsReportStatus.completed'),
};
