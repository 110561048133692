import { MerchantType } from '@/models/merchant';
import { request } from '@/utils/request';
import { ElMessage } from 'element-plus';
import { type Ref, computed, watch, onBeforeUnmount, ref } from 'vue';
import { polling, downloadFromUrl } from '@/utils';
import { useI18n } from 'vue-i18n';

export function usePoiInsightsReport(merchant: Readonly<Ref<MerchantType>>, fetchData: () => Promise<void>) {
  const { t } = useI18n();
  const generateReportLoading = ref(false);

  const downloadButtonProps = computed(() => {
    const reportStatus = merchant.value.poi_insights_report_status;
    return {
      show: reportStatus !== 'pending',
      loading: reportStatus === 'in_progress',
      text: reportStatus === 'in_progress' ? t('merchant.poi_insights_report.generating') : t('merchant.poi_insights_report.download')
    };
  });

  const generateReportButtonProps = computed(() => {
    return {
      show: merchant.value.abilities?.generate_poi_insights_report,
      loading: generateReportLoading.value,
      text: merchant.value.poi_insights_report_file?.url ? t('merchant.poi_insights_report.re_generate') : t('merchant.poi_insights_report.generate')
    };
  });

  async function generateReport() {
    generateReportLoading.value = true;
    try {
      await request.post(`/merchants/${merchant.value.id}/generate_poi_insights_report`);
      await fetchData();
      ElMessage.success(t('merchant.poi_insights_report.generating_success'));
    } finally {
      generateReportLoading.value = false;
    }
  }

  function downloadReport() {
    downloadFromUrl(merchant.value.poi_insights_report_file?.url!, merchant.value.poi_insights_report_file?.filename || 'report.pptx', '_blank');
  }

  let pollingCancel: () => void;
  watch(
    () => merchant.value.poi_insights_report_status,
    (status) => {
      if (status === 'in_progress') {
        pollingCancel?.();
        pollingCancel = polling(fetchData, 5000);
      } else {
        pollingCancel?.();
      }
    },
    { immediate: true }
  );

  onBeforeUnmount(() => {
    pollingCancel?.();
  });

  return {
    generateReport,
    downloadReport,
    generateReportButtonProps,
    downloadButtonProps,
  };
}
