export default {
  '1688': '1688',
  'amazon': 'Amazon',
  'shopify': 'Shopify',
  'shoplazza': 'Shoplazza',
  'migrate_from': {
    'unknown': '不需要迁移商品数据'
  },
  'migrate_to': {
    'unknown': 'Unknown'
  },
  'policy_type': {
    'refund_policy': '退款条约',
    'privacy_policy': '隐私政策',
    'terms_of_service': '服务条约',
    'shipping_policy': '发货条约'
  },
  'migration_status': {
    'pending': '尚未开始',
    'already_setup_store': '完成店铺设置',
    'added_policy': '已添加政策',
    'added_payment_method': '已添加支付方式',
    'added_theme': '已添加主题',
    'syncing': '迁移商品中',
    'completed': '迁移已完成'
  },
  'overall_adjustment_price_type': {
    'increase_percentage': '价格上调百分比',
    'decrease_percentage': '价格下调百分比'
  },
  'migrate_task_status': {
    'pending': '尚未开始',
    'processing': '进行中',
    'completed': '已完成'
  },
  'google_integrate_status': {
    'pending': '尚未开始',
    'added_google_channel': '已安装Google Channel',
    'added_tracking_code': '已添加 Tracking Code',
    'completed': '已完成'
  },
  'automation_comaign_state': {
    'pending': '准备处理',
    'processing': '处理中',
    'completed': '已完成'
  },
  'appeal_target_gender': {
    'male': '男性',
    'female': '女性',
    'unknown': '不限'
  },
  'appeal_target_income': {
    'low': '低收入',
    'mid': '中等收入',
    'high': '高收入'
  },
  'appeal_reason': {
    'gmc_block': 'GMC 封号',
    'not_comply_terms_and_conditions': '广告账户被封 - 不遵守条款和条件',
    'other': '广告账户被封 - 其他'
  },
  'appeal_generate_content_state': {
    'pending': '尚未开始',
    'processing': '处理中',
    'completed': '已完成'
  },
  'campaign_types': {
    'Search': {
      'title': '搜索',
      'subtitle': '在 Google 搜索上恰当其时地向意向强烈的客户展示您的广告'
    },
    'Shopping': {
      'title': '购物',
      'subtitle': '向正在浏览商品的购物者展示您的产品'
    },
    'PMax': {
      'title': '效果最大化',
      'subtitle': '通过一个广告系列在 Google 所有平台上覆盖受众群体。'
    }
  },
  'shopping_campaign_bid_strategy_types': {
    'maximize_clicks': {
      'label': '尽可能争取更多点击次数',
      'descs': [
        '采用“尽可能争取更多点击次数”出价策略时，Google Ads 会自动设置适当的出价，在不超出预算的前提下为您争取尽可能多的点击',
        '每次点击费用的最高出价上限为所有的每次点击费用出价设定了上限。这可能会对您广告获得的点击次数造成限制。',
        '您在此处选择的策略将仅应用于此广告系列。'
      ]
    },
    'target_ROAS': {
      'label': '目标广告支出回报率',
      'descs': [
        '采用“目标广告支出回报率”出价策略时，Google Ads 会自动设置适当的出价，在达到您设置的目标广告支出回报率的前提下，尽可能争取更高的转化价值。某些转化的回报率可能高于或低于您设定的目标。',
        '您在此处选择的策略将仅应用于此广告系列。'
      ]
    },
    'manual_CPC': {
      'label': '每次点击费用人工出价',
      'descs': [
        '采用“每次点击费用人工”出价策略时，您需要自行为广告设置最高每次点击费用。'
      ]
    }
  },
  'review_state': {
    'hide': '隐藏',
    'published': '公开'
  },
  'boolean_select_map': {
    'true': '是',
    'false': '否'
  },
  pmaxCampaignBidStrategyTypes: {
    maximizeConversions: '转化次数',
    maximizeConversionValue: '转化价值',
  },
  googleUserListsStatus: {
    pending: '待推送',
    running: '推送中',
    success: '已推送',
    failed: '推送失败',
  },
  blogState: {
    pending: '待生成',
    processing: '生成中',
    generated: '已生成',
    pushing: '正在推送',
    pushed: '已推送',
  },
  tamarAiAccountReportStatus: {
    pending: '待生成',
    in_progress: '生成中',
    completed: '已生成',
    failed: '生成失败',
  },
  tamarAiAccountStoreType: {
    seller: '普通卖家店铺',
    vendor: 'VC店铺',
  },
  tamarAiAccountRegion: {
    NA: '北美',
    EU: '欧洲',
    FE: '远东',
  },
  poiInsightsReportStatus: {
    pending: '待生成',
    in_progress: '生成中',
    completed: '已生成',
  },
};
