export default {
  'name': '商户名',
  'company_name': '公司名',
  'email': '联系人邮箱',
  'brand_name': '品牌名称',
  'phone_number': '联系人手机号',
  'industry': '行业',
  'company_address': '公司地址',
  'company_established': '公司成立时间',
  'return_address': '退货地址',
  'return_contact': '退货联系人',
  'return_phone_number': '退货联系电话',
  'return_validity_time': '退货有效期（天数）',
  'shop_subdomain': '店铺子域',
  'return_email': '退货邮箱',
  'auto_sync': '每天同步',
  'enable_auto_sync': '开启每天同步',
  'disable_auto_sync': '关闭每天同步',
  'assignAgent': '分配代理人',
  'btn_import_merchant': '导入商户',
  'import_step_1_tip': '第一步：请销售从CRM【销售-商机】中导出所有赢单商机的.xlsx文件',
  'import_step_2_tip': '第二步：请运营上传导出的.xlsx文件，即可批量创建商户',
  'form_link': {
    'title': '商家信息表单链接',
    'expire_tip': '此链接<strong>24小时内</strong>有效',
    'copy_link': '复制链接',
    'get_link': '获取商家信息表单链接'
  },
  'product_type': '产品品类',
  'product_type_placeholder': '请填写。例如：真丝裙子',
  'product_type_tip': '请填写最多一个细分产品品类，借助AI生成素材。越精准越好 ，例如：真丝裙子 / 针织女装 / 木质家具，最多10个字，不能包含标点符号和空格',
  'currency': '货币',
  'brand_name_tip': '请填写英文名称',
  'poi_insights_report': {
    'generate': '生成行业报告',
    'generating': '正在生成行业报告',
    're_generate': '重新生成行业报告',
    'download': '下载行业报告',
    'generating_success': '正在生成行业报告'
  }
};
